a, a:link, a:visited, a:focus, a:hover, a:active{
  color:black;
  text-decoration:none;
}


.firstImage {
  width: 100%;
  height: auto;
  opacity: 0.2;
}

.mainGrid {
  display: grid;
}

.illustration {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100vw;
  padding-top: 10%;
}

figcaption{
  text-align: center;
  font-style: italic;
}

img {
  max-height: 50vh;
  width: 100%;
  object-fit: contain;
  object-position: center center;
}


.text {
  display: flex;
  flex-direction: column;
  padding: 10%;
}


.buttonLink {
  color: #000000;
  background-color: #ffffff;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  width: 35%;
  font-family: sans-serif;
  font-weight: 500;
  justify-content: center;
  display: flex;
  border: 2px solid black;
  border-radius: 10px;
}

.buttonLink:hover {
  color: #ffffff;
  background-color: #000000
},






@media (max-width: 767.98px) {
  .mainGrid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr);
  }

  .buttonLink {
    width: 50%;
  }

  .illustration {
    max-width: 100vw !important;
    padding-bottom: 5vh;
  }
}


@media (min-width: 768px) {
  .navbar-toggler {
    visibility: hidden;
  }

  .navbar-nav {
    display: flex !important; /* force display of page names on larger screens */
  }

  .mainGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .illustration {
    max-width: 50vw;
  }
}
